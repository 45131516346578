import React, { useState, useEffect } from "react"

const FadeOutOnScroll = ({ scroll = 100, children }) => {
   const [scrollPosition, setScrollPosition] = useState(0)

   useEffect(() => {
      const handleScroll = () => setScrollPosition(window.scrollY)
      window.addEventListener("scroll", handleScroll, { passive: true })
      return () => window.removeEventListener("scroll", handleScroll)
   }, [])

   return (
      <div
         className={`transform duration-700 ease-in-out ${
            scrollPosition > scroll ? "opacity-0 -translate-y-6" : "opacity-1 translate-y-0"
         } `}
      >
         {children}
      </div>
   )
}

export default FadeOutOnScroll
