import React from "react"
import { Link } from "gatsby"

function Button({
   linkTo,
   href,
   label,
   title,
   iconLeft,
   iconRight,
   size = "sm",
   isDark = false,
   containerCustomClasses,
   textCustomClasses,
   onClick,
   isRounded = true,
   hasShadow = true,
}) {
   const containerDefaultClasses = `group/button flex items-center space-x-2 inline-block text-center
   ${size === "sm" ? "py-2 px-3 3md:pl-3.5 3md:pr-4" : size === "md" ? "py-2 px-4 3md:pl-6 3md:pr-6 py-3" : ""}
   ${isDark ? "bg-dark hover:background-colour" : "border-2 border-dark hover:border-transparent background-colour "}
   ${isRounded ? "border-radius-md overflow-hidden" : "border-radius-sm"}
   ${containerCustomClasses}
   ${hasShadow ? "shadow-hover transition-all duration-300" : ""}`

   const iconDefaultClasses = `group-hover/button:underlined group-hover/button:text-dark ${
      size === "sm" ? "w-6 md:w-6" : size === "md" ? "w-7 md:w-7" : "w-6 md:w-6"
   }`

   const textDefaultClasses = ` ${size === "sm" ? "text-xs text-nowrap" : size === "md" ? "font-sm text-nowrap" : ""} ${
      isDark
         ? "inline-block font-mono font-bodybold uppercase text-offWhite group-hover/button:text-dark group-hover/button:underlined"
         : "inline-block font-mono font-bodybold uppercase text-dark group-hover/button:text-dark group-hover/button:underlined"
   }`

   const ButtonComponent = (
      <button className={containerDefaultClasses} onClick={onClick} title={label} aria-label={title} role="button">
         {iconLeft && <span className={`${textDefaultClasses} ${iconDefaultClasses}`}>{iconLeft}</span>}
         <p className={`${textDefaultClasses} ${textCustomClasses}`}>{label}</p>
         {iconRight && <span className={`${textDefaultClasses} ${iconDefaultClasses} `}>{iconRight}</span>}
      </button>
   )

   if (linkTo) {
      return <Link to={linkTo}>{ButtonComponent}</Link>
   } else if (href) {
      return (
         <a rel="noopener noreferrer" target="_blank" href={href}>
            {ButtonComponent}
         </a>
      )
   } else if (!href || !linkTo === null) {
      return <Link to={"#0"}>{ButtonComponent}</Link>
   }
}

export default Button
