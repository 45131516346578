import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { useSpring, animated } from "react-spring"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import ContainerSite from "../3_layouts/ContainerSite"
import Button from "../1_components/Button"
import IconMenu from "../svg/icons/menu.svg"
import IconClose from "../svg/icons/close.svg"
import IconEnvelope from "../svg/icons/envelope-simple.svg"
import IconEyeClosed from "../svg/icons/eye-closed.svg"
import IconEyeOpen from "../svg/icons/eye-open.svg"

const IconComponent = () => (
   <div className="block w-10 md:w-11 p-1.5 rounded-full border-2 border-dark background-colour">
      <span className="block group-hover:hidden">
         <IconEyeClosed />
      </span>
      <span className="hidden group-hover:block">
         <IconEyeOpen />
      </span>
   </div>
)

const NavItems = ({ navItems }) => (
   <div className="w-full hidden md:flex justify-between items-center">
      <ul className="w-full flex justify-around items-center">
         {navItems.map((item, i) => (
            <li key={i}>
               <AnchorLink to={item.to} title={item.text}>
                  <p className="font-bodybold text-xs uppercase text-hover">{item.text}</p>
               </AnchorLink>
            </li>
         ))}
      </ul>
      <Button
         isExternalLink={true}
         href="mailto:iampaolotodde@gmail.com"
         label="Get in touch"
         iconLeft={<IconEnvelope />}
      />
   </div>
)

const MobileNavItems = ({ navItems, onClick }) => (
   <div className="md:hidden z-100 space-top-header bg-secondary opacity-95 backdrop-blur-sm space-bottom-md h-screen">
      <ul className="flex flex-col space-y-4 text-left space-top-lg px-8">
         {navItems.map((item, i) => (
            <li key={i} onClick={onClick}>
               <AnchorLink to={item.to} title={item.text} stripHash>
                  <div className="inline-flex items-start space-x-1.5">
                     <span className="text-[3rem] mr-2 pt-1 text-stone-500">☞</span>
                     <p className="header-md uppercase text-dark">{item.text}</p>
                  </div>
               </AnchorLink>
            </li>
         ))}
         <li className="space-top-xs">
            <Button
               size="md"
               isExternalLink={true}
               href="mailto:iampaolotodde@gmail.com"
               label="Get in touch"
               iconLeft={<IconEnvelope />}
            />
         </li>
      </ul>
   </div>
)

const Navigation = () => {
   const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
   const [scrollPosition, setScrollPosition] = useState(0)

   useEffect(() => {
      const handleScroll = () => setScrollPosition(window.scrollY)
      window.addEventListener("scroll", handleScroll, { passive: true })
      return () => window.removeEventListener("scroll", handleScroll)
   }, [])

   const navItems = [
      { to: "/about", text: "About" },
      { to: "/#work", text: "Work" },
      { to: "/blog", text: "Blog" },
      { to: "/about#side-projects", text: "Creative Projects" },
   ]

   const toggleMobileNav = () => setIsMobileNavOpen((prev) => !prev)
   const closeMobileNav = () => setIsMobileNavOpen(false)

   const menuAnimation = useSpring({
      transform: isMobileNavOpen ? "translateX(10%)" : "translateX(100%)",
      opacity: isMobileNavOpen ? 1 : 1,
      config: { mass: 3, tension: 400, friction: 70 },
   })

   return (
      <header
         className={`fixed z-50 w-full top-0 space-top-xs right-0 left-0 pb-2 ${
            scrollPosition > 85
               ? "backdrop-blur-sm bg-bgColour/50 transform -translate-y-2 lg:-translate-y-5 duration-500 ease-in-out"
               : "bg-opacity-0 transform duration-500 ease-in-out"
         }`}
      >
         <ContainerSite>
            <nav className="flex justify-between items-center">
               <Link className="group" to="/" title="Home">
                  <IconComponent />
               </Link>
               <NavItems navItems={navItems} />
               <button className="md:hidden focus:outline-none" onClick={toggleMobileNav}>
                  {isMobileNavOpen ? <IconClose className="w-9 h-9" /> : <IconMenu className="w-9 h-9" />}
               </button>

               <animated.div
                  className="absolute -z-10 top-0 left-0 right-0"
                  style={menuAnimation}
                  onClick={closeMobileNav}
               >
                  <MobileNavItems navItems={navItems} onClick={closeMobileNav} />
               </animated.div>
            </nav>
         </ContainerSite>
      </header>
   )
}

export default Navigation
