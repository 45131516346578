import React from "react"
import PatternImg from "../../svg/blocks.svg"

function BackgroundPattern({ height = "40rem", fadeBgColor = "offWhite" }) {
   return (
      <div
         className={`absolute -z-50 h-[${height}] left-1/2
                     transform -translate-x-1/2 overflow-y-hidden opacity-60
                     -top-[15rem] xs:-top-[18rem] md:-top-[10rem] lg:-top-[10rem] xl:-top-[10rem]
                     w-[300%] xxs:w-[400%] xs:w-[250%] sm:w-[170%] md:w-[150%] lg:w-[130%] xl:w-[100%]`}
      >
         <span
            className={`absolute top-0 inset-0
                        bg-gradient-to-t
                        from-${fadeBgColor}
                        via-${fadeBgColor}/20
                        to-transparent`}
         ></span>
         <PatternImg />
      </div>
   )
}

export default BackgroundPattern
