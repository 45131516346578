import React from "react"
import PropTypes from "prop-types"
import ContainerSite from "../3_layouts/ContainerSite"
import Navigation from "../2_sections/Navigation"
import Footer from "../2_sections/Footer"
import FadeOutOnScroll from "../0_transitions/FadeOutOnScroll.js"
import TransitionPageIn from "../0_transitions/TransitionPageIn.js"
import BackgroundPattern from "../5_assets/static/BackgroundPattern.js"
import ScrollToTopButton from "../1_components/ScrollToTopButton"

const Layout = ({ children, location }) => {
   const allowedPathsBackgroundPattern = ["/", "/about/", "/404/", "/blog/"]
   const showBackgroundPattern = allowedPathsBackgroundPattern.includes(location.pathname)

   return (
      <div className="relative h-full w-full overflow-hidden background-colour">
         <Navigation />
         <TransitionPageIn>
            {showBackgroundPattern && (
               <FadeOutOnScroll>
                  <BackgroundPattern />
               </FadeOutOnScroll>
            )}
            <main>
               <ContainerSite>{children}</ContainerSite>
            </main>
         </TransitionPageIn>

         <div className={`fixed bottom-[8%] right-[6%]`}>
            <ScrollToTopButton />
         </div>

         <Footer />
      </div>
   )
}

Layout.propTypes = {
   children: PropTypes.node.isRequired,
   location: PropTypes.object.isRequired,
}

export default Layout
